import React from "react";
import "./App.css";
import { Stack, Box, Typography } from "@mui/material";
import goldMakingMachinePlaceholder from "./assets/images/gold-making-machine.webp";
import goldChainTool from "./assets/images/gold-chain-tool.webp";
import goldBrushTool from "./assets/images/gold-brush-tool.webp";
import polishTool from "./assets/images/polish-tool.webp";
import founderPlaceholder from "./assets/images/gamal-ali.webp";


// Navbar Component
const Navbar = () => {
  return (
    <nav className="navbar">
      <h1>Mazar USA</h1>
      <ul>
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#products">Products</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

// App Component
const App = () => {
  return (
    <div className="App">
      <Navbar />

      {/* Home Section */}
      <section id="home" className="section">
        <div className="container">
          <h2>Welcome to Mazar USA</h2>
          <p>
            Mazar USA is a leading provider of gold-making tools and supplies to
            manufacturers around the world, especially in Egypt. We offer a wide
            range of machinery that transforms gold into beautiful chains, along
            with brushes, polishing tools, and more.
          </p>
          <img
            src={goldMakingMachinePlaceholder}
            alt="Gold Making Machine"
            className="main-image"
          />
        </div>
      </section>

      {/* Products Section */}
      <section id="products" className="section">
        <div className="container">
          <h2
            style={{
              textAlign: "center",
            }}
          >
            Our Products
          </h2>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{
              width: "100%",
            }}
          >
            <Box textAlign="center">
              <img
                src={goldChainTool}
                alt="Gold Chain Machine"
                style={{ width: "100%" }}
              />
              <Typography variant="h6">Gold Chain Making Machine</Typography>
              <Typography variant="body1">
                High precision machines that turn gold into intricate chain
                designs.
              </Typography>
            </Box>

            <Box textAlign="center">
              <img
                src={goldBrushTool}
                alt="Gold Brushes"
                style={{ width: "100%" }}
              />
              <Typography variant="h6">Gold Brushes</Typography>
              <Typography variant="body1">
                Durable brushes for refining gold products.
              </Typography>
            </Box>

            <Box textAlign="center">
              <img
                src={polishTool}
                alt="Gold Manufacturing Tools"
                style={{ width: "100%" }}
              />
              <Typography variant="h6">Polishing Tools</Typography>
              <Typography variant="body1">
                Polish your gold creations to perfection.
              </Typography>
            </Box>
          </Stack>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="section">
        <div className="container">
          <h2>About Mazar USA</h2>
          <p>
            Mazar USA was founded by <strong>Gamal Ali</strong>, a passionate
            businessman born in Egypt, who was fascinated by the art of gold
            manufacturing in the historic city of Hussien, Cairo. His love for
            the craft led him to establish Mazar USA, which now supplies
            gold-making tools to manufacturers worldwide.
          </p>
          <img
            src={founderPlaceholder}
            alt="Founder Gamal Ali"
            className="founder-photo"
          />
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="section">
        <div className="container">
          <h2>Contact Us</h2>
          <p>
            Phone: <a href="tel:+17816402206">+1 (781) 640-2206</a>
          </p>
          <p>
            Email: <a href="mailto:mazarusa@aol.com">mazarusa@aol.com</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default App;
